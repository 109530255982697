// App.js

import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import toast, { Toaster } from 'react-hot-toast'
import { ColorRing } from 'react-loader-spinner';

function Message({ text }) {
  return (
    <div className='message-container'>
      <div className='question-container'>
        <img src="/user.png" alt="User" className="question-image" />
        <div className='question-name-text'><p>You</p></div>
      </div>
      <div className={`message bot}`}>
      <h5>{text.question}</h5>
    </div>
    
    <div className='answer-container'>
      <img src="/varan-gpt.png" alt="VaranGPT" className="answer-image" />
      <div className='answer-name-text'><p>VaranGPT</p></div>
    </div>
    <div className={`message user`}>
      <h5>{text.answer}</h5>
    </div>
    </div>
  );
}

function MessageList({ messages }) {
  return (
    <div className="message-list">
      {messages && messages.map((message, index) => (
        <Message key={index} text={message} isUser={message.isUser} />
     ))}
    </div>
  );
}

function MessageInput({ onSendMessage, question, setQuestion, loading }) {

  return (
    <form onSubmit={onSendMessage} className="input-container">
      <input
        type="text"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        placeholder="Ex. Varan's email id ?"
      />
      {
      loading ? 
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
        /> 
        : 
        <button type="submit">Send</button>
    }
    </form>
  );
}

function App() {
  const [messages, setMessages] = useState([]);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('')
  const [loading, setLoading] = useState(false);

  const validations = () => {
    if (question.length === 0) {
      toast('Ask a question about Varan...')
      return false
    }
    if (question.length > 100) {
      toast('Question length is too long, Try again with a shorter length...')
      return false
    }
    return true
  }

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!validations()) {
      return false;
    }
    setLoading(true);
    const backendUrl = `https://api-colors.gen-ai.varanbhalla.com/gpt/varan/${question}`;
    const localhostUrl = `http://127.0.0.1:5000/gpt/varan/${question}`
  
    try {
      const response = await axios.get(backendUrl);
      setLoading(false);
      if (response.data && response.data.answer) {
        const cleanedResponse = response.data.answer.replace(/【.*?】/g, ''); // Remove additional text
        setAnswer(cleanedResponse);
        setMessages([{ question, answer: cleanedResponse }, ...messages]);
      }
      setLoading(false);
      toast('Response Generated ...');
      setQuestion('');
      setAnswer('');
    } catch (error) {
      if (error.response && error.response.status === 429) {
        toast('Too many requests. Kindly try again later...');
      } else if (error.response && error.response.status === 500) {
        toast('Internal server error. Kindly try again later');
      } else {
        toast('An error occurred. Kindly try again later.');
      }
      setLoading(false);
    }
  };
  


  return (
    <div className="chat-app">
      <div className="app-container">
        <h1 className="title">Varan GPT</h1>
      </div>
      <MessageList 
        messages={messages} 
      />
      <MessageInput 
        onSendMessage={handleSendMessage} 
        question={question} 
        setQuestion={setQuestion} 
        loading={loading}
      />
      <Toaster />
    </div>
  );
}

export default App;
